import type { ITestPlansData } from 'models/test-plans';
import type {
  ICertificationComment,
  ICertificationDetails,
  ICertificationFile
} from 'models/certifications';
import { baseApi } from './base.api';

interface UpdateCertificationResponse {
  success: boolean;
  certification: ICertificationDetails;
}

export const certificationApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Certification', 'Comments', 'Files', 'TestPlan']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCertificationDetails: builder.query<ICertificationDetails, string>({
        query: (id) => `/certifications/${id}`,
        providesTags: ['Certification']
      }),
      getPyxisProject: builder.query<ICertificationDetails, string>({
        query: (id) => `/certifications/${id}/project`,
        providesTags: ['Certification']
      }),

      updateCertification: builder.mutation<
        UpdateCertificationResponse,
        { id: string; certificationDetail: Partial<ICertificationDetails> }
      >({
        query: ({ id, certificationDetail }) => ({
          url: `/certifications/${id}`,
          method: 'PATCH',
          body: certificationDetail
        }),
        invalidatesTags: ['Certification']
      }),
      getCertificationComments: builder.query<ICertificationComment[], string>({
        query: (certificationId) =>
          `/certifications/${certificationId}/comments`,
        providesTags: ['Comments']
      }),
      addCertificationComment: builder.mutation<
        ICertificationComment,
        { certificationId: string; comment: Partial<ICertificationComment> }
      >({
        query: ({ certificationId, comment }) => ({
          url: `/certifications/${certificationId}/comments`,
          method: 'POST',
          body: comment
        }),
        invalidatesTags: ['Comments']
      }),
      getCertificationFiles: builder.query<ICertificationFile[], string>({
        query: (certificationId) => `/certifications/${certificationId}/files`,
        providesTags: ['Files']
      }),
      uploadCertificationFile: builder.mutation<
        ICertificationFile,
        { certificationId: string; file: FormData }
      >({
        query: ({ certificationId, file }) => ({
          url: `/certifications/${certificationId}/files`,
          method: 'POST',
          body: file
        }),
        invalidatesTags: ['Files']
      }),
      getCertificationTestPlan: builder.query<ITestPlansData, string>({
        query: (certificationId) =>
          `/certifications/${certificationId}/test-plan`,
        providesTags: ['TestPlan']
      })
    })
  });

export const {
  useGetCertificationDetailsQuery,
  useUpdateCertificationMutation,
  useGetCertificationCommentsQuery,
  useAddCertificationCommentMutation,
  useGetCertificationFilesQuery,
  useUploadCertificationFileMutation,
  useGetCertificationTestPlanQuery,
  useGetPyxisProjectQuery
} = certificationApi;

export { certificationApi as api };
