import type { IComponentProps } from 'models/components';
import React from 'react';

import { AlertReducer } from './AlertReducer';

import type { IError } from 'models/common';

const HTTPErrorCodeMapping: { [key: number]: string } = {
  400: '400 Bad Request',
  401: '401 Unauthorized',
  403: '403 Forbidden',
  404: '404 Not Found',
  405: '405 Method Not Allowed',
  408: '408 Request Timeout',
  409: '409 Conflict',
  410: '410 Gone',
  429: '429 Too Many Requests',
  500: '500 Internal Server Error',
  501: '501 Not Implemented',
  502: '502 Bad Gateway',
  503: '503 Service Unavailable',
  504: '504 Gateway Timeout'
};

type FnTypes<K> = (p: K) => void;

export interface IAlert {
  title: string;
  key?: number;
  variant?: AlertType;
  timeout?: number;
  message?: React.ReactNode;
}
interface IAlertContext {
  alerts: IAlert[];
  addDangerMessage: FnTypes<IAlert>;
  addSuccessMessage: FnTypes<IAlert>;
  addWarningMessage: FnTypes<IAlert>;
  addInfoMessage: FnTypes<IAlert>;
  addFetchErrorMessage: (error: unknown | IError) => void;
  removeAlert: (key: number) => void;
}
export const AlertContext = React.createContext<IAlertContext>({
  alerts: [],
  addDangerMessage: () => undefined,
  addSuccessMessage: () => undefined,
  addWarningMessage: () => undefined,
  addInfoMessage: () => undefined,
  addFetchErrorMessage: () => undefined,
  removeAlert: () => undefined
});

export const useAlertContext = (): IAlertContext =>
  React.useContext(AlertContext);

enum AlertType {
  DANGER = 'danger',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export const AlertContextProvider: React.FC<IComponentProps> = (props) => {
  const [alerts, dispatch] = React.useReducer(AlertReducer, []);

  const addDangerMessage = (alert: IAlert) => {
    dispatch({ type: 'ADD', alert: { variant: AlertType.DANGER, ...alert } });
  };

  const addSuccessMessage = (alert: IAlert | string) => {
    const _alert = typeof alert === 'string' ? { title: alert } : alert;
    dispatch({ type: 'ADD', alert: { variant: AlertType.SUCCESS, ..._alert } });
  };

  const addWarningMessage = (alert: IAlert) => {
    dispatch({ type: 'ADD', alert: { variant: AlertType.WARNING, ...alert } });
  };

  const addInfoMessage = (alert: IAlert) => {
    dispatch({ type: 'ADD', alert: { variant: AlertType.INFO, ...alert } });
  };

  const addFetchErrorMessage = (err: unknown | Error | IError) => {
    const error: IError = err as IError;
    const code = (error as IError).code || (error as IError).status;
    const genericError =
      typeof code === 'number' ? HTTPErrorCodeMapping[code] : null;
    const title =
      typeof error === 'object'
        ? genericError || error.message || error.responseText || 'Network Error'
        : error;
    const message =
      typeof error === 'object' && 'detailMessage' in error
        ? error.detailMessage
        : genericError
          ? error.message
          : 'Something went wrong. We are working on getting this fixed as soon as we can. You may be able to try again.';
    addDangerMessage({ title, message });
  };

  const removeAlert = (key: number) => {
    dispatch({ type: 'REMOVE', key });
  };

  return (
    <AlertContext.Provider
      value={{
        alerts,
        addDangerMessage,
        addSuccessMessage,
        addWarningMessage,
        addInfoMessage,
        addFetchErrorMessage,
        removeAlert
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};
