import Keycloak from 'keycloak-js';
import { getAuthUrl } from 'utilities/env-utils';

export const keycloak = new Keycloak({
  url: getAuthUrl(),
  realm: 'redhat-external',
  clientId: 'certpx'
});

export type { Keycloak };
