import { updateToken } from 'features/auth';
import Keycloak from 'keycloak-js';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { client } from 'services/adapters/xhr';
import analytics from 'utilities/analytics-utils';
import { getAuthUrl } from 'utilities/env-utils';

import store from 'store/index';
import AuthWrapper from './AuthenticationWrapper';

import type { IAuthState, IAuthAction, IAuthUser } from 'models/auth';
const kc = new Keycloak({
  url: getAuthUrl(),
  realm: 'redhat-external',
  clientId: 'certpx'
});

interface IAuthContext {
  state: IAuthState;
  login: () => void;
  logout: () => void;
  // checkRole: (role: string, resource?: string) => boolean;
}

const initialState: IAuthState = {
  user: {} as IAuthUser,
  token: null,
  profile: null,
  isAuthenticated: false,
  isLoading: false,
  isRefreshing: false,
  error: null
};

const initialValue: IAuthContext = {
  state: initialState,
  login: () => ({}),
  logout: () => ({})
};

const AuthContext = createContext<IAuthContext>(initialValue);

const authReducer = (state: IAuthState, action: IAuthAction) => {
  switch (action.type) {
    case 'TOKEN_REFRESH':
    case 'LOGIN':
    case 'TOKEN_EXPIRED':
      store.dispatch(updateToken(action.payload.token));
      client.authorization(action.payload.token);
      return {
        ...state,
        ...{
          ...action.payload,
          isAuthenticated: true,
          isRefreshing: false,
          isLoading: false,
          error: null
        }
      };
    case 'LOGOUT':
      return { ...initialState };
    case 'START_LOADING':
      return { ...state, ...{ isLoading: true, error: null } };
    case 'STOP_LOADING':
      return {
        ...state,
        ...{ isLoading: false, isRefreshing: false, error: null }
      };
    case 'START_REFRESHING':
      return { ...state, ...{ isRefreshing: true, error: null } };
    case 'ON_READY':
      return { ...state, ...{ isAuthenticated: action.payload } };
    case 'LOAD_USER_PROFILE':
      return { ...state, ...{ profile: action.payload } };
    case 'FAILED':
      return {
        ...state,
        ...{ isLoading: false, isRefreshing: false, error: action.payload }
      };
    default:
      throw new Error();
  }
};

// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const [keycloak] = useState<Keycloak>(kc);
  const [state, dispatch] = useReducer<React.Reducer<IAuthState, IAuthAction>>(
    authReducer,
    initialState
  );

  let ignore = false;
  useEffect(() => {
    dispatch({ type: 'START_LOADING' });
    if (!ignore) {
      if (!keycloak) return undefined;
      init();
    }
    return () => {
      ignore = true;
    };
  }, [keycloak]);

  const init = async () => {
    try {
      addAdapters();
      keycloak
        .init({
          onLoad: 'login-required',
          enableLogging: true,
          pkceMethod: 'S256',
          responseMode: 'query',
          scope: 'openid api.partner_certification profile email roles'
        })
        .then((authenticated) => {
          if (authenticated) {
            analytics.track('auth-success');
            dispatch({
              type: 'LOGIN',
              payload: {
                token: keycloak.token,
                user: keycloak.tokenParsed as IAuthUser
              }
            });
            supportSessionJS();
          } else {
            analytics.track('auth-error', { error: 'Unauthenticated' });
            dispatch({ type: 'FAILED', payload: 'User not authenticated' });
          }
        })
        .catch((error) => {
          console.error(error);
          analytics.track('auth-error', { error: JSON.stringify(error) });
          dispatch({ type: 'FAILED', payload: 'Authentication Failed' });
        });
    } catch (error) {
      console.error(error);
      analytics.track('auth-error', { error: JSON.stringify(error) });
      dispatch({ type: 'FAILED', payload: 'Authentication Failed' });
    } finally {
      dispatch({ type: 'STOP_LOADING' });
    }
  };

  const addAdapters = () => {
    // keycloak.onReady = async function () {
    //   dispatch({ type: 'ON_READY', payload: keycloak.authenticated });
    //   await keycloak.loadUserProfile();
    // };
    keycloak.onAuthSuccess = function () {
      dispatch({
        type: 'LOGIN',
        payload: {
          token: keycloak.token,
          user: keycloak.tokenParsed as IAuthUser
        }
      });
    };
    keycloak.onAuthError = function () {
      analytics.track('auth-error', { error: 'Authentication failed' });
      dispatch({ type: 'FAILED', payload: 'Authentication failed' });
    };
    keycloak.onAuthRefreshSuccess = function () {
      dispatch({
        type: 'LOGIN',
        payload: {
          token: keycloak.token,
          user: keycloak.tokenParsed as IAuthUser
        }
      });
    };
    keycloak.onAuthRefreshError = function () {
      analytics.track('auth-error', { error: 'Authentication refresh failed' });
      dispatch({ type: 'FAILED', payload: 'Authentication Refresh Failed' });
    };
    keycloak.onAuthLogout = function () {
      dispatch({ type: 'LOGOUT' });
      keycloak.clearToken();
    };

    keycloak.onTokenExpired = async () => {
      dispatch({ type: 'START_REFRESHING' });
      try {
        await keycloak.updateToken(15 * 60); // 15 mins
        dispatch({
          type: 'TOKEN_EXPIRED',
          payload: {
            token: keycloak.token,
            user: keycloak.refreshTokenParsed as IAuthUser
          }
        });
      } catch (err) {
        dispatch({ type: 'FAILED', payload: 'Error refreshing token' });
        analytics.track('auth-error', {
          error: 'Authentication refresh failed'
        });
      }
    };
  };

  const supportSessionJS = () => {
    window.keycloak = keycloak as Window['keycloak'];

    window.sessionjs = {
      ...(keycloak as Keycloak),
      jwtToken: keycloak.token,
      _state: {
        keycloak: {
          token: keycloak.token,
          isTokenExpired: keycloak.isTokenExpired
        }
      },
      _keycloak: keycloak as Keycloak,
      isAuthenticated: () => keycloak.authenticated
    } as Window['sessionjs'];
  };

  const login = async () => {
    try {
      if (!keycloak) return;
      await keycloak.login();
      dispatch({
        type: 'LOGIN',
        payload: {
          token: keycloak.token,
          user: keycloak.tokenParsed as IAuthUser
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    await keycloak.logout();
    dispatch({ type: 'LOGOUT' });
  };

  // const checkRole = (role: string, resource?: string) => {
  //   return keycloak.hasResourceRole(role, resource);
  // }

  return (
    <AuthContext.Provider value={{ state, login, logout }}>
      <AuthWrapper>{children}</AuthWrapper>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  if (AuthContext === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return useContext(AuthContext);
}
