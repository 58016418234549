import './App.scss';

import ArtifactsMetaData from 'context/artifacts/ArtifactsMetaData';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import LoadingPage from 'views/LoadingPage/LoadingPage';

import ToastAlertGroup from './components/General/Alert/ToastAlertGroup';
import { ErrorBoundary } from './components/General/Error/ErrorBoundary';
import AppProvider from './context/AppProvider';
import { AppRouter } from './routes/AppRouters';
import store from './store/index';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AppProvider>
          <ArtifactsMetaData />
          <ToastAlertGroup />
          <Router>
            <Suspense fallback={<LoadingPage message='Authorizing ...' />}>
              <AppRouter />
            </Suspense>
          </Router>
        </AppProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
