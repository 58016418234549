import { baseApi } from './base.api';

import type { LinkedResource } from 'models/products';

export const resourceApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['LinkedResources']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getResources: builder.query<LinkedResource[], number>({
        query: (partnerProductId) => ({
          url: `products/${partnerProductId}/linked-resources`,
          method: 'GET'
        }),
        providesTags: (result = [], _error, partnerProductId) => [
          { type: 'LinkedResources', id: partnerProductId },
          ...result.map(({ id }) => ({ type: 'LinkedResources' as const, id }))
        ]
      }),
      addResources: builder.mutation<
        LinkedResource[] | { error: string },
        {
          partnerProductId: number;
          resources: Omit<LinkedResource, 'id'>[];
        }
      >({
        query: ({ partnerProductId, resources }) => ({
          url: `products/${partnerProductId}/linked-resources`,
          method: 'POST',
          body: resources
        }),
        invalidatesTags: (_result, _error, { partnerProductId }) => [
          { type: 'LinkedResources', id: partnerProductId }
        ],
        async onQueryStarted(
          { partnerProductId, resources },
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            resourceApi.util.updateQueryData(
              'getResources',
              partnerProductId,
              (draft) => {
                draft.push(
                  ...resources.map((resource, index) => ({
                    ...resource,
                    id: index
                  }))
                );
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        }
      }),
      updateResources: builder.mutation<
        LinkedResource[] | { error: string },
        {
          partnerProductId: number;
          resource: LinkedResource;
        }
      >({
        query: ({ partnerProductId, resource }) => ({
          url: `products/${partnerProductId}/linked-resources`,
          method: 'PATCH',
          body: resource
        }),
        invalidatesTags: (_result, _error, { partnerProductId }) => [
          { type: 'LinkedResources', id: partnerProductId }
        ],
        async onQueryStarted(
          { partnerProductId, resource },
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            resourceApi.util.updateQueryData(
              'getResources',
              partnerProductId,
              (draft) => {
                const index = draft.findIndex((r) => r.id === resource.id);
                if (index !== -1) {
                  // Update existing resource
                  draft[index] = { ...draft[index], ...resource };
                } else {
                  // Add new resource
                  draft.push(resource);
                }
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        }
      }),
      removeResources: builder.mutation<
        void | { error: string },
        {
          partnerProductId: number;
          resourceIds: number[];
        }
      >({
        query: ({ partnerProductId, resourceIds }) => ({
          url: `products/${partnerProductId}/linked-resources`,
          method: 'DELETE',
          body: resourceIds
        }),
        invalidatesTags: (_result, _error, { partnerProductId }) => [
          { type: 'LinkedResources', id: partnerProductId }
        ],
        async onQueryStarted(
          { partnerProductId, resourceIds },
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            resourceApi.util.updateQueryData(
              'getResources',
              partnerProductId,
              (draft) => {
                return draft.filter(
                  (resource) => !resourceIds.includes(resource.id)
                );
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        }
      })
    })
  });

export const {
  useGetResourcesQuery,
  useAddResourcesMutation,
  useUpdateResourcesMutation,
  useRemoveResourcesMutation
} = resourceApi;
