import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';

import { keycloak } from './keycloak';

import type { RootState } from 'store';
import type Keycloak from 'keycloak-js';
// import analytics from 'utilities/analytics-utils';

interface IAuthUser {
  username: string;
  account: { id: string; number: string };
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  isVerified: boolean;
}
export interface AuthState {
  loading: boolean;
  roles: string[];
  token: string | undefined;
  error: null | string;
  success: boolean;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  isAuthenticated: boolean;
  user: IAuthUser;
}

const initialState = {
  loading: false,
  status: 'idle',
  user: {} as IAuthUser,
  roles: [],
  token: undefined,
  error: null,
  success: false,
  isAuthenticated: false
} satisfies AuthState as AuthState;

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (keycloak: Keycloak, { rejectWithValue }) => {
    try {
      const refreshed = await keycloak.updateToken(15 * 60);
      if (refreshed) {
        return keycloak.token;
      }
      throw new Error('Token refresh failed');
    } catch (e) {
      const error = e as Error;
      return rejectWithValue(error.message);
    }
  }
);

export const handleAuthError = createAsyncThunk(
  'auth/handleAuthError',
  async (error: Error, { rejectWithValue }) => {
    return rejectWithValue(error.message);
  }
);

export const addHooks = (
  keycloak: Keycloak,
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
) => {
  keycloak.onAuthSuccess = () => {
    dispatch(authSlice.actions.authSuccess());
  };

  keycloak.onAuthError = () => {
    dispatch(handleAuthError(new Error('Authentication failed')));
  };

  keycloak.onTokenExpired = async () => {
    dispatch(refreshToken(keycloak));
  };

  keycloak.onAuthLogout = () => {
    dispatch(authSlice.actions.logout());
  };
};

const supportSessionJS = () => {
  window.keycloak = keycloak as Window['keycloak'];

  window.sessionjs = {
    ...(keycloak as Keycloak),
    jwtToken: keycloak.token,
    _state: {
      keycloak: {
        token: keycloak.token,
        isTokenExpired: keycloak.isTokenExpired
      }
    },
    _keycloak: keycloak as Keycloak,
    isAuthenticated: () => keycloak.authenticated
  } as Window['sessionjs'];
};

export const initialize = createAsyncThunk(
  'auth/initialize',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      addHooks(keycloak, dispatch);
      const authenticated = await keycloak.init({
        onLoad: 'login-required',
        enableLogging: true,
        pkceMethod: 'S256',
        responseMode: 'query',
        scope: 'openid roles address web-origins profile phone email'
      });

      if (!authenticated) {
        await keycloak.login();
      }
      supportSessionJS();
      let user = {} as IAuthUser;
      if (keycloak.tokenParsed) {
        const {
          preferred_username,
          email,
          account_id,
          account_number,
          email_verified,
          name,
          given_name,
          family_name
        } = keycloak.tokenParsed;
        user = {
          username: preferred_username,
          account: { id: account_id, number: account_number },
          name,
          given_name,
          family_name,
          email,
          isVerified: email_verified
        };
      }

      return {
        user,
        token: keycloak.token,
        roles: keycloak.realmAccess?.roles || []
      };
    } catch (e) {
      const error = e as Error;
      return rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSuccess: (state) => {
      state.status = 'succeeded';
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.status = 'idle';
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      state.roles = [];
      state.error = null;
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isAuthenticated = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state: AuthState) => {
        state.status = 'loading';
      })
      .addCase(initialize.fulfilled, (state: AuthState, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.roles = action.payload.roles;
      })
      .addCase(initialize.rejected, (state: AuthState, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(refreshToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(handleAuthError.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  }
});

// Actions
export const { updateToken } = authSlice.actions;

// Selectors
export const selectAuth = (state: RootState) => state.auth;
export const selectToken = (state: RootState) => state.auth.token;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const selectUser = (state: RootState) => state.auth.user;
export const selectRoles = (state: RootState) => state.auth.roles;

export default authSlice.reducer;
