import { getApiUrl } from 'utilities/env-utils';

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';

import type { RootState } from 'store/index';

const baseQuery = fetchBaseQuery({
  baseUrl: `${getApiUrl()}/hydra/cwe/rest/v1.0/`,
  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState() as RootState;

    if (auth && auth.token && auth.isAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    }

    return headers;
  }
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, api, extraOptions) => baseQuery(args, api, extraOptions);

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: ['Certification', 'Partner', 'Product', 'Artifacts'],
  endpoints: () => ({})
});
