import type { PropsWithChildren } from 'react';
import type { IPartner } from './partner';
import type { IProduct } from './products';

export type State = {
  isLoading: boolean;
  products: IProduct[];
  partners: IPartner[];
};

export enum ActionTypes {
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_PARTNERS = 'GET_PARTNERS',
  ADD_PRODUCT = 'ADD_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  GET_PRODUCTS_BY_PARTNER = 'GET_PRODUCTS_BY_PARTNER'
}

export type Action =
  | { type: 'START_LOADING' }
  | { type: 'STOP_LOADING' }
  | { type: 'GET_PRODUCTS'; payload: IProduct[] }
  | { type: 'GET_PARTNERS'; payload: IPartner[] }
  | { type: 'ADD_PRODUCT'; payload: IProduct }
  | { type: 'UPDATE_PRODUCT'; payload: IProduct }
  | { type: 'GET_PRODUCTS_BY_PARTNER'; payload: { id: number } };

export type ProductContextType = {
  addProduct: (product: IProduct) => void;
  updateProduct: (product: IProduct) => void;
  getProductsByPartner: (id: number) => IProduct[];
  getProduct: (id: number) => IProduct;
} & State;

export interface ProductProviderProps extends PropsWithChildren {}
